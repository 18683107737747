<template>
  <div class="main-container">
    <div class="main-container__menu" :class="menuClasses">
      <div
        v-for="(item, index) of menuItems"
        :id="item.id"
        :key="index"
        class="main-container__menu__item"
        :class="{
          active: getIsActive(item),
        }"
        @click="onClickItem(item)"
      >
        <div>
          {{ item.name }}
        </div>
        <div v-if="item.icon">
          <img :src="item.icon" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIsMobileOrTablet } from '@/utils/misc';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'MenuSectionsLanding',
  data() {
    return {
      playsTypeOptions: ['goals', 'bestPlays'],
      summaryRoutesNames: ['player', 'team'],
      stringLastRouteName: '',
    };
  },
  computed: {
    // ...mapState('mediaGeneral', ['initialDataForLandingButton', 'player']),
    ...mapState('leagueGeneral', ['arrayTournaments', 'booleanAreLiveGames']),
    ...mapState('loginGeneral', ['user']),
    // ...mapGetters('loginGeneral', [
    //   'user',
    //   'isPlayer',
    //   'getURL',
    //   'getSeasons',
    //   'getTournaments',
    //   'getTournamentSelected',
    //   'getSeasons',
    //   'getSeasonSelected',
    //   'showDashboard',
    // ]),
    // ...mapGetters('mediaGeneral', ['isPlayerGoalkeeper']),
    mediaPlayerType() {
      return this.summaryRoutesNames.includes(this.$route.name) ? 'summary' : 'general';
    },
    hasActiveTime() {
      return this.user.active_time === 1;
    },
    menuClasses() {
      return [getIsMobileOrTablet() ? 'main-container__menu--is-mobile' : ''];
    },
    menuItems() {
      if (this.arrayTournaments.length) {
        const menuItems = [
          { id: 'vt', name: 'Ver todo', mainRoute: { name: 'viewAll' }, routesNames: ['viewAll'] },
          {
            id: 'ca',
            name: 'Calendario',
            mainRoute: { name: 'extendedCalendar' },
            routesNames: ['extendedCalendar'],
          },
          // { id: 'vi', name: 'Videos', mainRoute: { name: '' }, routesNames: [] },
          { id: 'ta', name: 'Tabla', mainRoute: { name: 'table' }, routesNames: ['table'] },
          { id: 'dg', name: 'Datos GolStats', mainRoute: { name: 'golStatsData' }, routesNames: ['golStatsData'] },
          { id: 'mj', name: 'Mejores Jugadores', mainRoute: { name: 'topsSection' }, routesNames: ['topsSection'] },
          { id: 'ap', name: 'Apuestas', routesNames: [] },
          {
            id: 'ju',
            name: 'Jugadores',
            mainRoute: { name: 'playerTeams' },
            routesNames: ['playerTeams', 'players', 'playerProfile'],
          },
          { id: 'eq', name: 'Equipos', mainRoute: { name: 'teams' }, routesNames: ['teams', 'teamProfile'] },
        ];
        if (this.booleanAreLiveGames) {
          menuItems.splice(1, 0, { id: 'li', name: 'Live', icon: '/icons/icn_live_option_dark.svg' });
        }
        return menuItems;
      }
      return [];
    },
  },
  watch: {
    '$route.name'(newValue) {
      this.stringLastRouteName = newValue;
    },
    menuItems(newValue, oldValue) {
      if (newValue.length && !oldValue.length) {
        this.onClickItem(newValue[0]);
      }
    },
  },
  methods: {
    ...mapMutations('leagueGeneral', ['setBooleanIsLiveGamesModalOpen']),
    getIsActive(item) {
      if (this.$route.query.section) {
        return this.$route.query.section === item.id;
      }
      return item.routesNames && item.routesNames.includes(this.$route.name);
    },
    getRouteData() {
      if (this.hasActiveTime) {
        return {
          routesNames: ['activeTime'],
        };
      } else {
        return null;
      }
    },
    openMediaPlayer() {
      const modalInitialState = {
        seasonId: this.initialDataForLandingButton[this.mediaPlayerType].seasonId,
        tournaments: this.initialDataForLandingButton[this.mediaPlayerType].tournamentsForModalLauncher,
        categoryIds: [...this.initialDataForLandingButton[this.mediaPlayerType].currentCategories],
        mainSectionType:
          this.initialDataForLandingButton[this.mediaPlayerType].playsType === this.playsTypeOptions[0] ? 1 : 2,
        playListType: this.initialDataForLandingButton[this.mediaPlayerType].selectedType,
        matchResult: [1, 2, 3],
        currentPlayIndex: this.initialDataForLandingButton[this.mediaPlayerType].playlistIndex,
        isFromLandingButton: true,
      };
      const player = this.initialDataForLandingButton[this.mediaPlayerType].player;
      if (player) {
        const playerId = player.player_id || player.id;
        if (player.position_name === 'Portero' || player.positionId === 1) {
          modalInitialState.goalkeeperIds = [playerId];
        } else {
          modalInitialState.playerIds = [playerId];
        }
      } else if (this.initialDataForLandingButton[this.mediaPlayerType].teamId) {
        modalInitialState.teamId = this.initialDataForLandingButton[this.mediaPlayerType].teamId;
      }
      this.$store.commit('mediaModal/setModalInitialState', modalInitialState);
      this.$store.commit('mediaModal/setIsModalOpen', true);
    },
    goToGolTipster() {
      const element = document.getElementById('golTipsterLanding');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    onClickItem(item) {
      if (item.id === 'li') {
        this.setBooleanIsLiveGamesModalOpen(true);
      } else if (item.id === 'ap') {
        if (this.$route.name === 'viewAll') {
          this.goToGolTipster();
        } else {
          this.$router.push('/view-all#golTipster');
          setTimeout(() => {
            this.goToGolTipster();
          }, 4000);
        }
      } else if (item.id === 'ca') {
        if (this.$route.name !== item.mainRoute.name) {
          this.$router.push(item.mainRoute);
        }
      } else if (item.id === 'vi') {
        this.openMediaPlayer();
      } else if (this.stringLastRouteName !== item.mainRoute.name) {
        this.$router.push(item.mainRoute);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__menu {
    width: 1270px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-bottom: 6px;

    &--is-mobile {
      -webkit-overflow-scrolling: touch;
      /* Hide scroll bar in firefox*/
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      padding: 8px 24px 4px;
      border-radius: 25px;
      background-color: rgba(203, 238, 109, 0.5);
      white-space: nowrap;
      color: #132739;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: -0.16px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div:first-child {
        padding-bottom: 0.2em;
      }

      &.active {
        border: solid 2px #111f2c;
        background-color: rgba(203, 238, 109, 0.5);
      }

      & img {
        height: 24px;
        width: 24px;
        min-height: 24px;
        min-width: 24px;
        margin-left: 6px;
      }
    }
  }

  @media screen and (max-width: 1326px) {
    overflow-x: auto;
    overflow-y: hidden;

    &__menu {
      width: calc(100vw - 32px);
      overflow-x: auto;
      overflow-y: hidden;

      &__item {
        margin: 0 4px;
      }
    }
  }
}

#me {
  @media screen and (max-width: 380px) {
    display: none;
  }
}
::-webkit-scrollbar {
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #e5e7ec;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e5e7ec;
}
</style>
